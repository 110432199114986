
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch
} from "vue";
import { Field } from "vee-validate";
import { useStore } from "vuex";

export interface PreferredVenue {
  id: number;
  name: string;
  jobs: number;
}

export default defineComponent({
  name: "preferred-venues-dropdown",
  components: { Field },
  props: {
    clientId: {
      type: [Number, null] as PropType<number | null>,
      default: () => null
    },
    autoSelect: {
      type: Boolean as PropType<boolean>,
      default: () => true
    }
  },
  emits: ["selected-venue"],
  setup: function(props, { emit }) {
    const value = ref<number | null>(null);
    const loading = ref<boolean>(false);
    const field = ref();
    const store = useStore();
    const triggerValidation = ref<boolean>(true);

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    const preferredVenues = computed<PreferredVenue[]>(
      (): PreferredVenue[] => store.getters["ListsModule/preferredVenues"]
    );

    function resetField() {
      value.value = null;
      field.value?.reset();
    }

    function fetchPreferredVenues(): void {
      setLoading(true);
      triggerValidation.value = false;
      if (props.autoSelect) {
        resetField();
      }
      store
        .dispatch("ListsModule/fetchPreferredVenues", props.clientId)
        .then(() => {
          triggerValidation.value = true;
          if (
            props.autoSelect &&
            preferredVenues.value.length &&
            props.clientId
          ) {
            value.value = preferredVenues.value[0].id;
          }
          setLoading(false);
        })
        .catch(() => {
          triggerValidation.value = true;
          setLoading(false);
        });
    }

    watch(
      () => value.value,
      () => {
        if (triggerValidation.value) emit("selected-venue", value.value);
      }
    );

    watch(
      () => props.clientId,
      () => {
        fetchPreferredVenues();
      }
    );

    onMounted((): void => {
      fetchPreferredVenues();
    });

    function setValue(data: number): void {
      value.value = data;
    }

    return {
      preferredVenues,
      resetField,
      setValue,
      loading,
      value,
      field
    };
  }
});
