
import { onMounted, toRefs, ref, watch, computed } from "vue";
import Inputmask from "inputmask";
import moment from "moment";
import { useStore } from "vuex";

export default {
  name: "shift-duration-widgget",
  props: {
    shift: {
      type: Object,
      required: true
    },
    isEditingJob: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore();
    const final = ref<string>("");
    const regex = "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$";
    const { shift } = toRefs(props);
    function calculateDuraion() {
      const start = moment(
        `${props.shift.startDate} ${props.shift.startHour}`,
        "DD/MM/YYYY kk:mm"
      );
      const end = moment(
        `${props.shift.startDate} ${props.shift.endHour}`,
        "DD/MM/YYYY kk:mm"
      );
      if (start.valueOf() > end.valueOf()) end.add(1, "days");
      const duration = moment.duration(end.diff(start));
      const hours =
        Math.floor(duration.asHours()) >= 10
          ? Math.floor(duration.asHours())
          : `0${Math.floor(duration.asHours())}`;
      const minutes =
        duration.asMinutes() % 60 <= 9
          ? `0${duration.asMinutes() % 60}`
          : duration.asMinutes() % 60;
      final.value = `${hours}:${minutes}`;
    }
    function notNull(start, end) {
      return start && end;
    }
    const hasStartHour = computed(() => {
      return !(props.shift.startHour && props.shift.startHour.match(regex));
    });
    function handleDurationSet() {
      if (props.isEditingJob) {
        console.log(final.value);
        store.dispatch("EditJobModule/changeDuration", {
          duration: final.value,
          shiftID: props.shift.id
        });
      } else {
        store.dispatch("JobCreateModule/setShiftEndHour", {
          duration: final.value,
          shiftID: props.shift.id
        });
      }
    }
    function calculateIfShiftHasHours() {
      if (
        notNull(props.shift.startHour, props.shift.endHour) &&
        props.shift.startHour.match(regex) &&
        props.shift.endHour.match(regex)
      ) {
        calculateDuraion();
      } else {
        final.value = "";
      }
    }
    watch(
      shift,
      () => {
        calculateIfShiftHasHours();
      },
      { deep: true }
    );
    onMounted(() => {
      Inputmask({
        regex: regex
      }).mask(`duration_${props.shift.id}`);
      calculateIfShiftHasHours();
    });

    return {
      final,
      hasStartHour,
      handleDurationSet
    };
  }
};
