import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    size: "large",
    clearable: "",
    filterable: "",
    onClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('clear'))),
    placeholder: "Select contact on location",
    class: "w-100"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_option, {
        label: "Create new...",
        onClick: _ctx.handleCreateNew
      }, null, 8, ["onClick"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyCols, (col) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          onClick: ($event: any) => (_ctx.$emit('change_col', col.id)),
          key: col.id,
          label: col.name,
          value: col.id
        }, null, 8, ["onClick", "label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}