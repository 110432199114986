import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.final) = $event)),
      id: `duration_${$props.shift.id}`,
      class: "form-control me-2 w-75 h-40px",
      disabled: $setup.hasStartHour,
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.handleDurationSet && $setup.handleDurationSet(...args)))
    }, null, 40, _hoisted_1), [
      [
        _vModelText,
        $setup.final,
        void 0,
        { lazy: true }
      ]
    ])
  ]))
}