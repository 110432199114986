export const roleNames = {
  admin: "Admin",
  supportAdmin: "Support admin",
  keyAccountManager: "Key account manager",
  worker: "Worker",
  booker: "Booker",
  projectManager: "Project manager",
  internalCOL: "Internal COL",
  externalCOL: "External COL",
  applicant: "Applicant",
  crewHandler: "Crew Handler"
};
