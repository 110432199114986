<template>
  <div class="modal fade" id="addNewShiftModal" data-toggle="modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addNewShiftModal">Add new shift</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>
        <div class="modal-body">
          <div class="row gy-5 g-xl-8">
            <div class="col-12">
              <div class="card">
                <div class="card-header border-0 pt-5 px-6">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1"
                      >Create shift</span
                    >
                    <span class="text-muted mt-1 fw-bold fs-7"
                      >Configure shift details</span
                    >
                  </h3>
                </div>
                <div class="card-body py-3 px-0">
                  <div class="row px-6">
                    <div class="col-6">
                      <label class="required fs-6 fw-bold mb-2"
                        >Shift Date</label
                      >
                      <el-date-picker
                        class="input-class mt-3 mt-sm-0 ms-0 col-md-7 col-sm-8 w-100"
                        clearable
                        name="startDate"
                        size="large"
                        v-model="shiftData.dateStart"
                        type="date"
                        placeholder="Select start date"
                        format="ddd DD/MM/YYYY"
                        value-format="DD/MM/YYYY"
                      >
                      </el-date-picker>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="startDate" />
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <label class="required fs-6 fw-bold mb-2"
                        >Start time</label
                      >
                      <div class="start d-flex ali">
                        <input
                          v-model="shiftData.startHour"
                          id="startHour_addNew"
                          class="form-control me-2 w-70px h-40px"
                        />
                      </div>
                    </div>
                    <div class="col-2">
                      <label class="required fs-6 fw-bold mb-2">End time</label>
                      <div class="start d-flex ali">
                        <input
                          v-model="shiftData.endHour"
                          id="finishHour_addNew"
                          class="form-control me-2 w-70px h-40px"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row px-6">
                    <div class="col-12">
                      <div class="card-header border-0 pt-5 px-0">
                        <h3 class="card-title align-items-start flex-column">
                          <span class="card-label fw-bolder fs-3 mb-1"
                            >Select skills</span
                          >
                          <span class="text-muted mt-1 fw-bold fs-7"
                            >Select the required skills for the shift.</span
                          >
                        </h3>
                      </div>
                      <AddSkillsMenu
                        :company-id="currentCompanyId"
                        :key="key"
                        @booked="handleBook"
                      ></AddSkillsMenu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="clearResidualData"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-success"
            data-bs-dismiss="modal"
            @click="save"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import AddSkillsMenu from "@/components/jobs/Edit/AddSkillsMenu.vue";
import Inputmask from "inputmask";
import { mapActions, mapGetters } from "vuex";

function initialData() {
  return {
    dateStart: null,
    dateEnd: null,
    duration: null,
    startHour: null,
    finishHour: null,
    skills: {}
  };
}

export default defineComponent({
  name: "add-new-shift-modal",
  components: {
    AddSkillsMenu
  },
  props: {
    isEditingJob: {
      type: Boolean,
      default: true
    },
    companyId: {
      type: [Number, null],
      default: () => null
    }
  },
  data() {
    return {
      shiftData: initialData(),
      key: 1,
      element: null
    };
  },
  watch: {
    company() {
      this.fetchFrequentlyUsedSkills(this.clientId);
    }
  },
  computed: {
    ...mapGetters("EditJobModule", ["job"]),
    company() {
      return this.clientId;
    },
    currentCompanyId() {
      if("company" in this.job) {
        if(this.isEditingJob) {
          if(this.companyId) {
            return this.companyId;
          }
          return this.job.company.id;
        }
      }
      return null;
    }
  },
  mounted() {
    Inputmask({
      regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask("#startHour_addNew");

    Inputmask({
      regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask("#finishHour_addNew");

    this.element = document.getElementById("addNewShiftModal");
    if(this.element) {
      this.element.addEventListener("hidden.bs.modal", this.handleModalHideEvent)
    }
  },
  beforeUnmount() {
    if(this.element) {
      this.element.removeEventListener("hidden.bs.modal", this.handleModalHideEvent);
    }
  },
  methods: {
    ...mapActions("EditJobModule", ["addNewShift"]),
    ...mapActions("JobCreateModule", ["fetchFrequentlyUsedSkills"]),
    handleModalHideEvent() {
      this.key++;
    },
    handleBook(data) {
      this.shiftData.skills = data;
    },
    save() {
      if(this.isEditingJob) this.addNewShift(this.shiftData);
      else this.$emit("newShift", this.shiftData);
      this.shiftData = initialData();
      this.key++;
    },
    clearResidualData() {
      this.shiftData = initialData();
      this.key++;
    }
  }
});
</script>
