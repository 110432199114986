
import { defineComponent, ref, computed, PropType, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "cols-dropdown",
  props: {
    initialValue: {
      type: Number as PropType<number>,
      default: null
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const value = ref<number | null>(null);
    const companyCols = computed<object[]>(
      () => store.getters["ListsModule/companyCols"]
    );
    watch(
      props,
      () => {
        value.value = props.initialValue;
      },
      { deep: true }
    );
    function handleCreateNew() {
      value.value = null;
      emit("create_new_col");
    }
    return {
      value,
      handleCreateNew,
      companyCols
    };
  }
});
