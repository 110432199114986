
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  watch,
  reactive,
  ref
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { roleNames } from "@/core/helpers/role_names";
import { useStore } from "vuex";
import * as Yup from "yup";
import Swal from "sweetalert2";

export default defineComponent({
  name: "create-new-col-modal",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props: {
    company: {
      type: Number as PropType<number>,
      required: true
    },
    inJobEdit: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const formComponent = ref();
    const companies = computed(() => store.getters["ListsModule/companies"]);
    const roles = computed(() => store.getters["ListsModule/roles"]);
    const data = reactive({
      name: "",
      phone: "",
      email: "",
      password: "",
      isEmployee: false as boolean,
      company: null as number | null
    });
    const selectedCompany = computed(() =>
      props.inJobEdit
        ? props.company
        : store.getters["JobCreateModule/selectedCompany"]
    );
    const error = ref<boolean>(false);
    onMounted(() => {
      data.company = props.company;
      store.dispatch("ListsModule/fetchRoles");
      if (0 === companies.value.length) {
        store.dispatch("ListsModule/fetchCompaniesAll", { dropdown: true });
      }
    });
    const client = computed(() => {
      return companies.value.find(
        company => company.value == selectedCompany.value
      );
    });
    const validationSchema = ref(
      Yup.object().shape({
        name: Yup.string().required("Name is a required field.")
      })
    );
    watch(data, () => {
      if (!data.isEmployee) {
        data.password = "";
      }
      error.value = false;
    });
    function submit() {
      if (data.isEmployee && data.email.trim() == "") {
        error.value = true;
        return;
      }
      isLoading.value = true;
      store
        .dispatch("UsersModule/checkEmailFree", data.email)
        .then(() => {
          const payload = { ...data };
          let role = {} as any;
          if (data.isEmployee) {
            role = roles.value.find(
              role => role.title == roleNames.internalCOL
            );
          } else {
            role = roles.value.find(
              role => role.title == roleNames.externalCOL
            );
          }
          payload["role"] = role.id;
          store
            .dispatch("UsersModule/createCOL", payload)
            .then(response => {
              isLoading.value = false;
              Swal.fire({
                icon: "success",
                timer: 1000,
                title: "Created successfully"
              }).then(() => {
                emit("success", response);
              });
            })
            .catch(error => {
              isLoading.value = false;
              Swal.fire({
                icon: "error",
                timer: 1000,
                title: error.message
              });
            });
        })
        .catch(() => {
          isLoading.value = false;
          formComponent.value.setFieldError(
            "email",
            "Email is already in use."
          );
        });
    }
    function handleClear() {
      data.company = null;
    }
    return {
      data,
      handleClear,
      submit,
      client,
      formComponent,
      isLoading,
      validationSchema,
      error
    };
  }
});
