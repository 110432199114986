<template>
  <el-select
    v-model="value"
    clearable
    filterable
    size="large"
    placeholder="Select a CH"
    class="w-100"
  >
    <el-option
      v-for="item in crewHandlers"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "CHDropdown",
  props: {
    defaultValue: {
      type: [Number, String],
      required: false
    }
  },
  data() {
    return {
      value: ""
    };
  },
  methods: {
    ...mapActions("ListsModule", ["fetchCrewHandlers"]),
    setData(data) {
      this.value = data;
    }
  },
  mounted() {
    this.fetchCrewHandlers();
    if (this.defaultValue) this.value = this.defaultValue;
  },
  computed: {
    ...mapGetters("ListsModule", ["crewHandlers"])
  }
});
</script>
