
import { computed, defineComponent, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { fireToast } from "@/core/helpers/swal";

interface BookerSearchResult {
  id: number;
  name: string;
}

export default defineComponent({
  name: "search-job-booker-dropdown",
  emits: ["valueChange", "cleared"],
  setup: function(props, { emit }) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const model = ref<number | null>(null);

    function setLoading(state: boolean): void {
      loading.value = state;
    }

    const searchResults = computed<BookerSearchResult[]>(
      (): BookerSearchResult[] =>
        store.getters["JobCreateModule/bookerSearchResults"]
    );

    function handleSearch(queryString: string): void {
      setLoading(true);
      store
        .dispatch("JobCreateModule/searchBookers", queryString)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    onUnmounted((): void => {
      store.dispatch("JobCreateModule/setSearchResults", []);
    });

    function setInitialData(data: BookerSearchResult) {
      store.dispatch("JobCreateModule/setSearchResults", [data]);
      model.value = data.id;
    }

    function handleVisibilityChange(isVisibleAfter: boolean): void {
      if (!isVisibleAfter && !model.value) {
        store.dispatch("JobCreateModule/setSearchResults", []);
      }
    }

    function handleClear(): void {
      emit("cleared");
      store.dispatch("JobCreateModule/setSearchResults", []);
    }

    function setDataById(id: number): void {
      store.dispatch("ListsModule/fetchUserById", id)
        .then((user: BookerSearchResult) => {
          setInitialData(user);
        })
        .catch(error => {
          fireToast(error.message ?? "Error has occurred", false);
        });
    }

    return {
      handleVisibilityChange,
      setInitialData,
      searchResults,
      handleSearch,
      handleClear,
      setDataById,
      loading,
      model
    };
  }
});
